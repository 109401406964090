Multivac.checkout = (function($){
  $(handleReload);

  var pricesReloadPending = false;

  return {
    submitStep: function() {
      var
        $this = $(this),
        $form = $(".checkout-step__form"),
        redirectToStep = $this.data("checkout-redirect-to-step") || $form.data("checkout-next-step-id"),
        redirectToUrl = $this.data("checkout-redirect-to-url")
      ;
      if(redirectToStep) {
        $form.find("input[name='nextStepId']").val(redirectToStep);
      }
      if(redirectToUrl) {
        $form.attr("data-ajax-url", redirectToUrl);
      }
      $form.trigger("submit");
    },
    chooseTableRow: chooseTableRow,
    onStepSubmitSuccess: function() {
      window.requestAnimationFrame(handleReload);
    },
    onTermsAndConditionsCheckboxClick: onTermsAndConditionsCheckboxClick,
    onOrderClick: onOrderClick,
    onIgnoreWarningAndOrderClick: onIgnoreWarningAndOrderClick,
    pricesReloadResponseHandler: pricesReloadResponseHandler,
    removeVoucher: removeVoucher,
    handleLoyaltyPoints: handleLoyaltyPoints
  };

  function handleReload() {
    var
      $form = $(".checkout-step__form"),
      nextStepButtonTitle = $form.data("checkout-next-step-button-title"),
      isLastStep = /^true$/i.test($form.data("checkout-last-step"))
    ;
    if(isLastStep) {
      $(".checkout").addClass("checkout--last-step");
    } else {
      $(".checkout").removeClass("checkout--last-step");
      if(nextStepButtonTitle) {
        $(".checkout__next-step-button .button").text(nextStepButtonTitle);
      }
    }
    resetTermsAndConditionsCheckbox();
    refreshTermsAndConditions();
    refreshPlaceOrderButton();
    checkIfPricesNeedReload();
  }

  function onTermsAndConditionsCheckboxClick() {
    var
      $source = $(this),
      $target = $(".checkout-step__form--last .checkout__terms-and-conditions")
    ;
    window.requestAnimationFrame(function(){
      var checked = $source.is(":checked");
      if(checked) {
        $target.prop("checked", checked).attr("checked", true);
      } else {
        $target.prop("checked", false).removeAttr("checked");
      }
      refreshPlaceOrderButton();
    });
  }

  function onIgnoreWarningAndOrderClick() {
    var
      $lightboxActions = $(".checkout-duplicate-order-lightbox__actions"),
      $buttons = $lightboxActions.find(".button"),
      $warningConfirmed = $(".checkout-step__form--last .checkout__duplicate-warning-confirmed"),
      $terms = $(".checkout-step__form--last .checkout__terms-and-conditions")
    ;
    $warningConfirmed.val(true);
    $terms.prop("checked", true).attr("checked", true);
    $buttons.attr("disabled", true);
    onOrderClick();
  }

  function onOrderClick() {
    var
      $form = $(".checkout-step__form--last"),
      placeOrderStepName = $form.data("checkout-place-order-step-id"),
      placeOrderStepUrl = $form.data("checkout-place-order-step-url") || $form.data("ajax-url")
    ;
    if (preCheckValidity($form)) {
      $form
        .data("ajax-url","")
        .removeAttr("data-ajax-url")
        .removeAttr("target")
        .find("input[name='nextStepId']").val(placeOrderStepName)
      ;
      if(placeOrderStepUrl) {
        $form.attr("action", placeOrderStepUrl);
      }
    }
    $form
      .on("submit", onOrderSubmit)
      .each(triggerSubmitWithValidation)
      .off("submit", onOrderSubmit)
    ;
    refreshPlaceOrderButton();
  }

  function onOrderSubmit() {
    var
      $form = $(this),
      target = $form.data("ajax-on-xhr-success-target"),
      $target = $(target),
      waitClass = $form.data("ajax-wait-class")
    ;
    $form.addClass("submit-wait-lock "+waitClass);
    $target.addClass(waitClass);
    refreshPlaceOrderButton();
  }

  function preCheckValidity($form) {
    var valid = true;
    $form.find(":input").each(function(){
      if(valid) {
        valid = this.checkValidity();
      }
    });
    return valid;
  }

  function triggerSubmitWithValidation() {
    $('<input type="submit">').hide().appendTo(this).click().remove();
  }

  function chooseTableRow(e) {
    var $target = $((e.originalEvent && e.originalEvent.target) || e.target);
    if(!$target.is(".input--radio")) {
      $(this).find(".input--radio").each(checkInputRadio);
    }
  }

  function checkInputRadio() {
    var
      $this = $(this),
      $others = $this.parents("tbody").find(".input--radio").not($this)
    ;
    $others.removeAttr("checked").prop("checked", false);
    $this.trigger("click");
  }

  function checkIfPricesNeedReload() {
    var
      $form = $(".checkout-step__form"),
      reloadNeeded = /^true$/i.test($form.data("checkout-reload-prices"))
    ;
    if (reloadNeeded && $(".checkout__prices-reload-trigger").length > 0) {
      reloadPricesDebounced();
    }
  }

  function reloadPricesDebounced() {
    if($(".checkout__prices--loading").length) {
      pricesReloadPending = true;
    } else {
      reloadPrices();
    }
  }

  function reloadPrices() {
    $(".checkout__prices-reload-trigger").trigger("click");
    $(".checkout__place-order .button").attr("disabled", true);
  }

  function pricesReloadResponseHandler() {
    if (pricesReloadPending) {
      pricesReloadPending = false;
      window.requestAnimationFrame(reloadPricesDebounced);
    } else {
      window.requestAnimationFrame(refreshPlaceOrderButton);
    }
  }

  function resetTermsAndConditionsCheckbox() {
    $(".checkout__place-order input[type='checkbox']:checked").removeAttr("checked").prop("checked", false);
  }

  function refreshTermsAndConditions() {
    var
      gtcLightboxExisting = $("#checkout-gtc").length > 0,
      $checkbox = $(".checkbox.checkout__terms-and-conditions")
    ;
    if (gtcLightboxExisting) {
      $checkbox.css("display","flex");
    } else {
      $checkbox.css("display", "none");
    }
  }

  function refreshPlaceOrderButton() {
    var
      $placeOrderBlock = $(".checkout__place-order"),
      pricesLoading = $(".checkout__prices--loading").length > 0,
      $form = $(".checkout-step__form--last"),
      waitClass = $form.data("ajax-wait-class"),
      asyncLoading = $("#checkout-async-block."+waitClass).length > 0,
      $termsAndConditions = $(".checkbox.checkout__terms-and-conditions:visible input"),
      termsAndConditionsChecked = $termsAndConditions.is(":checked"),
      $button = $placeOrderBlock.find(".button")
    ;
    if(!asyncLoading && !pricesLoading && (!$termsAndConditions.length || termsAndConditionsChecked)) {
      $button.removeAttr("disabled");
    } else {
      $button.attr("disabled", true);
    }
  }

  function removeVoucher(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this)
      .find('+input').prop("checked", true)
      .closest("form").trigger("submit")
    ;
  }

  function handleLoyaltyPoints(checkbox) {
    const checkbox1 = document.getElementById('applyTotal');
    const checkbox2 = document.getElementById('applyAmount');

    if (checkbox.id === 'applyTotal' && checkbox.checked) {
      checkbox2.checked = false;
    } else if (checkbox.id === 'applyAmount' && checkbox.checked) {
      checkbox1.checked = false;
    }
  }
})(jQuery);
